import { useRouter } from 'next/router'
import ErrorPage from 'next/error'
import Layout from '@/components/Layout'
import {
  getAllPostsWithSlug,
  getPageData,
  getNavigation,
  getFooterNavigation,
} from '@/lib/api'
import Sections from '@/components/Sections'

export default function Page({
  pageData,
  navigationData,
  footerNavigationData,
  preview,
}) {
  const { sections, slug } = pageData
  const router = useRouter()

  if (!router.isFallback && !slug) {
    return <ErrorPage statusCode={404} />
  }

  const isHomePage = router.asPath === '/'

  const sectionPageData = {
    shortName: pageData.shortName,
  }

  return (
    <Layout
      preview={preview}
      pageData={pageData}
      navigationData={navigationData}
      footerNavigationData={footerNavigationData}
      isHomePage={isHomePage}
    >
      {router.isFallback ? (
        <h1>Loading…</h1>
      ) : (
        <>
          {/* {previousPathname && (
              <button
                type="button"
                onClick={() => router.back()}
                aria-label="Go back to articles"
                className="group mb-8 flex h-10 w-10 items-center justify-center rounded-full bg-white shadow-md shadow-zinc-800/5 ring-1 ring-zinc-900/5 transition dark:border dark:border-zinc-700/50 dark:bg-zinc-800 dark:ring-0 dark:ring-white/10 dark:hover:border-zinc-700 dark:hover:ring-white/20 lg:absolute lg:-left-5 lg:mb-0 lg:-mt-2 xl:-top-1.5 xl:left-0 xl:mt-0"
              >
                <ArrowLeftIcon className="h-4 w-4 stroke-zinc-500 transition group-hover:stroke-zinc-700 dark:stroke-zinc-500 dark:group-hover:stroke-zinc-400" />
              </button>
            )} */}
          <Sections
            sections={sections}
            preview={preview}
            sectionPageData={sectionPageData}
          />
        </>
      )}
    </Layout>
  )
}
export async function getStaticProps(context) {
  const { params, locale, locales, defaultLocale, preview = null } = context

  const pageData = await getPageData({
    slug: (!params.slug ? ['/'] : params.slug).join('/'),
    locale,
    preview,
  })

  const navigationData = []

  for (const locale of locales) {
    const localeNavData = await getNavigation(locale)
    navigationData.push({ [locale]: localeNavData })
  }

  const footerNavigationData = []

  for (const locale of locales) {
    const localeFooterNavData = await getFooterNavigation(locale)
    footerNavigationData.push({ [locale]: localeFooterNavData })
  }

  return {
    props: {
      pageData: pageData,
      navigationData: navigationData,
      footerNavigationData: footerNavigationData,
      preview: preview,
    },
    revalidate: 10,
  }
}

export async function getStaticPaths({ locales }) {
  const allPaths = []

  for (const locale of locales) {
    const allPages = await getAllPostsWithSlug({ locale })

    allPages?.pages.data.map((page) => {
      const slugArray = !page.attributes.slug
        ? false
        : page.attributes.slug.split('/')

      allPaths.push({
        params: {
          slug: slugArray,
        },
        locale: page.attributes.locale,
      })
    })
  }

  return {
    paths: allPaths,
    fallback: false,
  }
}
